$(document).ready(function () {
  customFilterMobileDropdown();
  initEvents();
  offersDetailAccordion();
  customOffCanvas();
  initDateSelection();
  checkForDropdownOffers();
  customCookieBar();
  customLoyaltyLogin();
  initScroll();
  customForms();
  initVideoPopups();
  initRoomCarousel();
  initLangDropdown();
  initImageCarousel();
  initRoomsSlider();
  window["initDetail"]();
  window["initGallery"]();
  initAlternatingBackgrounds();
  customDetail();
  initNoSlider();
  initRiggsHomeSlider();
  initCustomIntro();
  initDropdownOffersSlider();
  initSeaContainersHome();
  initAccommodationDetailAccordionSlider();
  initUmbracoDropdown();
  initGHAPortal();
});

function initNoSlider() {
  if ($(".slider").length < 1) {
    $("body").addClass("no-slider");
  }
}

function initLangDropdown() {
  $(".header__lang button").on("click", function () {
    $(".header__lang").toggleClass("open");
  });
}

var initOffCanvas = function () {
  /* 	function addOverview() {
		var overviewText = $('.off-canvas__nav').data('overview-text') || 'Overview';
		$('.off-canvas__nav-primary > ul > li').each(function () {
			// Automatically add the "Overview" links to the landing page
			if ( $(this).find('ul').length > 0 ) {
				var overviewLink = $(this).find('a').first().attr('href');
				$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">' + overviewText + '</a></li>');
			}
		});
	} */

  if ($("body").hasClass("theme-lyle") === false) {
    // addOverview()
  }

  $(".off-canvas__nav-primary > ul > li > a").on("click", function () {
    if ($(this).next(".off-canvas__nav-sub").length > 0) {
      // only trigger if there are sub pages to show
      if ($(this).parent().hasClass("open")) {
        // second click of main item allows clicking directly through
        return true;
      } else {
        // close any other open items
        $(".off-canvas__nav-primary > ul > li.open")
          .removeClass("open")
          .find(".off-canvas__nav-sub")
          .slideUp();

        // and open the new one
        $(this).parent().addClass("open");
        $(this).next().slideDown();
      }
      return false; // prevent navigating
    }
  });
};

var initScroll = function () {
  if ($("body").hasClass("theme-riggs") && $(".riggs-home").length > 0) {
    $(".slider").append('<button class="scroller">Scroll to content</button>');
  }

  $(".scroller").on("click", function () {
    $("html, body").animate(
      {
        scrollTop: $(".slider").outerHeight() - 112,
      },
      750
    );
  });
};

var initDropdownOffersSlider = function () {
  if ($(window).width() < 640) {

    $('.dropdown-offers__button').on("click", function() {
      $('.dropdown-offers__inner').each(function () {

 
        $(this).slick({
          infinite: true,
          slidesToShow: 1,
          arrows: true,
          autoplay: false,
          prevArrow:
            '<button class="slick-prev"><img src="/assets/img/sea-containers/left-arrow-white.svg"></button>',
          nextArrow:
            '<button class="slick-next"><img src="/assets/img/sea-containers/left-arrow-white.svg"></button>'
      })  
    });
    })

}

};

initGallery = function () {
  $(".gallery__item a").on("click", function () {
    var $clickedThumbnail = $(this);

    // Dynamically load the gallery slider into a reveal popup
    var modal =
      '<div id="gallery__modal" class="gallery__modal full reveal loading grid-x" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-image cell large-10 large-offset-1"><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content cell large-10 large-offset-1 grid-x grid-margin-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div>';

    modal = modal.replace("{{src}}", $(this).attr("href"));
    modal = modal.replace("{{caption}}", $(this).data("title") || "");

    var cta = $(this).data("description") || "";
    if (
      typeof $(this).data("book-link") !== "undefined" &&
      $(this).data("book-link") !== ""
    ) {
      cta +=
        '<a href="' +
        $(this).data("book-link") +
        '" class="button button--navy" target="_blank">' +
        ($(this).data("book-title") || "Book Now") +
        "</a>";
    }
    modal = modal.replace("{{cta}}", cta);

    var $modal = modal;

    $("body").append($modal);

    $("#gallery__modal")
      .foundation()
      .on("closed.zf.reveal", function () {
        $("#gallery__modal").remove();
      });

    $("#gallery__modal").foundation("open");

    $(".gallery__modal-next").on("click", function () {
      $("#gallery__modal").foundation("close");
      $("#gallery__modal").remove();
      if ($clickedThumbnail.parent().next(":visible").length > 0) {
        // is the next thumb visible?
        $clickedThumbnail.parent().next(":visible").find("a").trigger("click");
      } else if ($clickedThumbnail.parent().nextUntil(":visible").length > 0) {
        // no, so find the next visible one
        $clickedThumbnail
          .parent()
          .nextAll(":visible")
          .first()
          .find("a")
          .trigger("click");
        $("#gallery__modal").foundation("close");
        $("#gallery__modal").remove();
      }
    });

    $(".gallery__modal-prev").on("click", function () {
      $("#gallery__modal").foundation("close");
      $("#gallery__modal").remove();
      if ($clickedThumbnail.parent().prev(":visible").length > 0) {
        // is the previous thumb visible?
        $clickedThumbnail.parent().prev(":visible").find("a").trigger("click");
      } else if ($clickedThumbnail.parent().prevUntil(":visible").length > 0) {
        // no, so find the next visible one that comes before this
        $clickedThumbnail
          .parent()
          .prevAll(":visible")
          .last()
          .find("a")
          .trigger("click");
        $("#gallery__modal").foundation("close");
        $("#gallery__modal").remove();
      }
    });

    return false;
  });

  refreshMasonry();
  $(".gallery").on("filter-changed", function () {
    setTimeout(refreshMasonry, 600);
  });

  Foundation.onImagesLoaded($(".gallery__item img"), refreshMasonry);

  function refreshMasonry() {
    $(".gallery__grid").masonry({
      columnWidth: ".gallery__item-sizer",
      itemSelector: ".gallery__item",
      percentPosition: true,
      horizontalOrder: false,
    });
  }

  // Remove the transition class after loading to avoid jumpy thumbnails
  setTimeout(function () {
    $(".gallery__item").removeClass("transition--2000");
  }, 2000);

  // Remove empty filter buttons (if not handled by the plugin)
  $(".filter__buttons button").each(function () {
    if ($(this).text() == "") {
      $(this).remove();
    }
  });
};

initDetail = function () {};

function customDetail() {
  $(".detail__accordion-wrap").accordiom({
    showFirstItem: true,
  });

  $(".detail__features-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    fade: true,
    speed: 1000,
    cssEase: "linear",
  });

  const featuresText = $(".detail__features-text");

  featuresText.each(function (i) {
    $(this).on("mouseover", function () {
      $(".detail__features-slider").slick("slickGoTo", i);
      $(".detail__features-text").addClass("fade");
      $(this).removeClass("fade");
    });

    $(this).on("mouseleave", function () {
      $(".detail__features-text").removeClass("fade");
    });
  });
}

var initFeaturedCarousel = function () {
  $(".featured-carousel__inner").slick({
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
};

var initSlider = function () {
  $(".slider__inner").reformSlider({
    autoPlay: true,
    speed: 7000,
    arrows: false,
    pagination: true,
    onLoad: function () {
      $(".has-mixed-media").addClass("show");
    },
  });

  if ($(".slider__slide iframe").length > 0) {
    $(".slider").addClass("has-video");

    coverViewport(".slider__slide iframe", true);
    $(window).on("resize", function () {
      coverViewport(".slider__slide iframe", true);
    });
  }
};

var initLandingSlider = function () {
  $(".landing__slider-inner").slick({
    fade: true,
    arrows: false,
    dots: true,
    autoplay: false,
  });

  if (
    $(".landing__row").last().hasClass("landing__row--light-background") ||
    $(".landing__row").last().hasClass("landing__row--dark-background")
  ) {
    $(".landing").addClass("landing--last-item-has-background-color");
  }

  if ($(".landing__slider-inner").length > 0) {
    $(window).on("resize", function () {
      setTimeout(function () {
        $(".landing__slider-inner").slick("refresh");
      }, 1000);
    });
  }
};

var initAnimateInView = function () {
  $(window).on("DOMContentLoaded load resize scroll", animateElementsInView);
  animateElementsInView();
};

function initRoomsSlider() {
  $(".rooms-slider__inner").slick({
    fade: true,
    arrows: true,
    dots: true,
    autoplay: false,
  });
}

function animateElementsInView() {
  $("[data-animate-in-view]").each(function () {
    if ($(this).hasClass("in-view")) {
      return;
    }

    if (isElementInViewport($(this))) {
      var elementToAnimate = this;

      $(elementToAnimate).addClass("in-view"); // log that this element has been seen

      setTimeout(function () {
        $(elementToAnimate).addClass(
          $(elementToAnimate).data("animate-in-view")
        ); // apply the animation class
        $(elementToAnimate).removeClass(
          $(elementToAnimate).data("animate-in-view-remove-class")
        ); // remove the pre-animation class
      }, 300);

      if (typeof $(elementToAnimate).data("map-load-on-view") != "undefined") {
        // only load this map once it's in view
        buildMap(elementToAnimate);
      }
    }
  });

  $("[data-animate-out-of-view]").each(function () {
    if ($(this).hasClass("in-view") && !isElementInViewport($(this))) {
      $(this).removeClass("in-view");
      $(this).removeClass($(this).data("animate-in-view"));
    }
  });
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport(el) {
  var rect = el[0].getBoundingClientRect();
  //var triggerPoint = rect.top + (rect.height / 3);
  var triggerPoint = rect.top + 150;

  if (triggerPoint >= 0 && triggerPoint <= window.innerHeight) {
    return true;
  } else {
    return false;
  }
}

var initMaps = function () {
  $("[data-map]").each(function () {
    buildMap(this);
  });
};

function buildMap(mapContainer) {
  var $mapContainer = $(mapContainer);
  var map, centerLat, centerLng, zoom, marker, markerLat, markerLng;

  if (typeof $mapContainer.data("map-center-coords") != "undefined") {
    centerLat = parseFloat(
      $mapContainer.data("map-center-coords").split(",")[0]
    );
    centerLng = parseFloat(
      $mapContainer.data("map-center-coords").split(",")[1]
    );
  } else {
    centerLat = 0;
    centerLng = 0;
  }

  zoom = $mapContainer.data("map-zoom") || 8;

  map = new google.maps.Map($mapContainer[0], {
    center: { lat: centerLat, lng: centerLng },
    zoom: zoom,
    zoomControl: true,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
      position: google.maps.ControlPosition.RIGHT_CENTER,
    },
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: mapDarkStyle,
  });

  if (typeof $mapContainer.data("map-marker-coords") != "undefined") {
    if (typeof $mapContainer.data("map-marker-coords") != "undefined") {
      markerLat = parseFloat(
        $mapContainer.data("map-marker-coords").split(",")[0]
      );
      markerLng = parseFloat(
        $mapContainer.data("map-marker-coords").split(",")[1]
      );
    } else {
      markerLat = 0;
      markerLng = 0;
    }

    marker = new google.maps.Marker({
      position: { lat: markerLat, lng: markerLng },
      map: map,
    });

    if (typeof $mapContainer.data("map-marker-url") != "undefined") {
      marker.setIcon({
        url: $mapContainer.data("map-marker-url"),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(62, 0),
      });
    }
  }

  $mapContainer.siblings("[data-map-marker]").each(function () {
    if (typeof $(this).data("map-marker-coords") != "undefined") {
      markerLat = parseFloat($(this).data("map-marker-coords").split(",")[0]);
      markerLng = parseFloat($(this).data("map-marker-coords").split(",")[1]);
    } else {
      markerLat = 0;
      markerLng = 0;
    }

    marker = new google.maps.Marker({
      position: { lat: markerLat, lng: markerLng },
      map: map,
    });

    if (typeof $(this).data("map-marker-url") != "undefined") {
      marker.setIcon($(this).data("map-marker-url"));
    }
  });

  var old_v = "",
    ds = new google.maps.DirectionsService(),
    dr = new google.maps.DirectionsRenderer();
  dr.setMap(map);
  dr.setPanel(jQuery("#map-directions-data").get(0));

  $(".location__form").on("submit", function (e) {
    e.preventDefault();

    var v = jQuery.trim($('input[name="directions_from"]').val());

    var directionsTo = $('input[name="directions_to"]').val();

    if (v != "" && v != old_v) {
      old_v = v;

      var r = {
        origin: v,
        destination: directionsTo,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      };

      ds.route(r, function (result, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          marker.setMap(null);
          dr.setDirections(result);

          setTimeout(function () {
            var x = document.getElementById("map-directions-data").innerHTML;
            var str = x;
            str = str.replace(/&/g, "amp;");
            str = str.replace(/>/g, "gt;");
            str = str.replace(/</g, "lt;");
            str = str.replace(/"/g, "quot;");
            str = str.replace(/'/g, "#039;");
          }, 2000);
        } else {
          console.log(result, status);
          alert("Location " + old_v + " not found.");
        }
      });
    }
  });
}

  var mapDarkStyle = [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
          {
              "saturation": "-100"
          }
      ]
  },
  {
      "featureType": "administrative.province",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
          {
              "saturation": -100
          },
          {
              "lightness": 65
          },
          {
              "visibility": "on"
          },
          {
              "color": "#d2d2d2"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "saturation": -100
          },
          {
              "lightness": "50"
          },
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
          {
              "saturation": "-100"
          },
          {
              "color": "#ffffff"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels",
      "stylers": [
          {
              "color": "#1f2435"
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels.text",
      "stylers": [
          {
              "color": "#1f2435"
          }
      ]
  },
  {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
          {
              "color": "#1f2435"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [
          {
              "lightness": "30"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "all",
      "stylers": [
          {
              "lightness": "40"
          }
      ]
  },
  {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
          {
              "saturation": -100
          },
          {
              "visibility": "simplified"
          }
      ]
  },
  {
      "featureType": "transit.station.rail",
      "elementType": "all",
      "stylers": [
          {
              "color": "#312d46"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "color": "#999696"
          },
          {
              "saturation": "0"
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
          {
              "hue": "#ffff00"
          },
          {
              "lightness": -25
          },
          {
              "saturation": -97
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
          {
              "lightness": -25
          },
          {
              "saturation": -100
          }
      ]
  }
  ]

function initEvents() {
  $(".events__carousel-inner").each(function () {
    $(this).addClass("item-count-" + $(this).find(".events__item").length);
  });

  if ($("body.theme-100-shoreditch").length > 0) {
    $(".events__carousel-inner.shoreditch").slick({
      slidesToShow: 4,
      arrows: true,
      appendArrows: ".events__header",
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  } else {
    $(".events__carousel-inner").slick({
      slidesToShow: 2,
      arrows: true,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }

  $(".promo-slider-inner").slick({
    fade: true,
    arrows: true,
    autoplay: false,
  });
}

function initRoomCarousel() {
  $(".rooms__carousel-inner").each(function () {
    $(this).addClass("item-count-" + $(this).find(".rooms__item").length);
  });

  if ($("body.theme-100-shoreditch").length > 0) {
    $(".rooms__carousel-inner.shoreditch").slick({
      slidesToShow: 4,
      arrows: true,
      appendArrows: ".rooms__header",
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  } else {
    $(".rooms__carousel-inner").slick({
      slidesToShow: 2,
      arrows: true,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }
}

function customFilterMobileDropdown() {
  if ($(".gallery").length === 0) return;

  var filterWatcher = setInterval(function () {
    if ($(".filter-controls-dropdown select").length === 1) {
      clearInterval(filterWatcher);
    }
    $(".filter-controls-dropdown select").selectric();
  }, 500);
}

function offersDetailAccordion() {
  $(".offers-detail__accordion").accordiom({
    showFirstItem: false,
  });
}

var initOtherRooms = function () {
  $(".other-rooms__carousel").slick({
    dots: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
    ],
  });
};

var initAccommodation = function () {
  $(".accommodation__slider-inner").slick({
    fade: true,
    arrows: true,
    autoplay: false,
  });

  // Apply odd/even styles that skip any quote or other sections added in between
  var i = 1;
  $(".accommodation__item").each(function () {
    if (i < 0) {
      $(this).addClass("accommodation__item--odd");
    }

    i = i * -1;
  });
};

function customOffCanvas() {
  if ($(".off-canvas__offer").length > 0) {
    $(".off-canvas").addClass("off-canvas--has-offer");
  } else {
    $(".off-canvas__sidebar").remove();
  }
}

function openBookingRoom() {}

function initDateSelection() {
  // $('#booking').foundation('open');

  // Only load 3rd party iframes when the booking widget is toggled
  function loadIframes() {
    $(".booking iframe").each(function () {
      if (typeof $(this).data("src") !== "undefined") {
        $(this).attr("src", $(this).data("src"));
      }
    });
  }

  $(".header__cta button").on("click", function () {
    loadIframes();
    $(window).trigger("resize"); // rejig the equalizer for the date fields
  });

  $("#book-table-btn").on("click", function () {
    $("#book-table").foundation("open");
  });

  //$("#book-room-btn").on("click", function () {
  //  $('#book-room form').trigger('submit');
  //});

  // Hide any open date pickers when changing tab
  $("#booking-tabs").on("change.zf.tabs", function () {
    $(".datepicker-container").addClass("datepicker-hide");
  });

  var bookingContainer = $(".booking__tabs-content-room form");

  var nowTemp = new Date();
  // Don't allow bookings until the hotel opens
  if (
    nowTemp < new Date("2022-03-01") &&
    $("body").hasClass("theme-100-shoreditch")
  ) {
    nowTemp = new Date("2022-03-01");
  }

  /*var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);*/

  var tomorrow = new Date(nowTemp.getTime() + 86400000); // + 1 day in ms
  tomorrow.toLocaleDateString();

  var $checkInField = bookingContainer.find(".check-in");
  var $checkOutField = bookingContainer.find(".check-out");

  $checkInField.on("hide.datepicker", function () {
    var checkInDate = $checkInField.datepicker("getDate");
    var checkOutDate = $checkOutField.datepicker("getDate");
    var dayAfterCheckIn = new Date(checkInDate);
    dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

    // Ensure checkout comes after checkin.
    // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
    if (checkInDate >= checkOutDate) {
      $checkOutField.datepicker("setDate", dayAfterCheckIn);
    }

    $checkOutField.datepicker("setStartDate", dayAfterCheckIn);

    $checkOutField[0].focus();
  });

  if ($("body").hasClass("theme-100-shoreditch")) {
    var checkin = $checkInField.datepicker({
      format: "dd-mm-yy",
      autoPick: true,
      autoHide: true,
      date: null,
      startDate: nowTemp,

      pick: function (e) {
        // Formatted date for the booking engine
        $('input[name="datein"]').val(
          pad(e.date.getMonth() + 1 * 1, 2) +
            "/" +
            e.date.getDate() +
            "/" +
            e.date.getFullYear()
        );
      },
    });

    var checkout = $checkOutField.datepicker({
      format: "dd-mm-yy",
      autoPick: true,
      autoHide: true,
      date: null,
      startDate: tomorrow,
      pick: function (e) {
        // Formatted date for the booking engine
        $('input[name="dateout"]').val(
          pad(e.date.getMonth() + 1 * 1, 2) +
            "/" +
            e.date.getDate() +
            "/" +
            e.date.getFullYear()
        );
      },
    });
  } else {
    var checkin = $checkInField.datepicker({
      format: "mm-dd-yy",
      autoPick: true,
      autoHide: true,
      date: null,
      startDate: nowTemp,

      pick: function (e) {
        // Formatted date for the booking engine
        $('input[name="datein"]').val(
          pad(e.date.getMonth() + 1 * 1, 2) +
            "/" +
            e.date.getDate() +
            "/" +
            e.date.getFullYear()
        );
      },
    });

    var checkout = $checkOutField.datepicker({
      format: "mm-dd-yy",
      autoPick: true,
      autoHide: true,
      date: null,
      startDate: tomorrow,
      pick: function (e) {
        // Formatted date for the booking engine
        $('input[name="dateout"]').val(
          pad(e.date.getMonth() + 1 * 1, 2) +
            "/" +
            e.date.getDate() +
            "/" +
            e.date.getFullYear()
        );
      },
    });
  }

  $(".booking select").selectric();

  $(".booking__tabs-content-room-why-book").accordiom({
    showFirstItem: false,
  });

  $("#booking__picker-select").on("change", function () {
    $(".tabs-panel").removeClass("is-active");
    $("#" + $(this).val()).addClass("is-active");
  });

  // Always open the booking widget for any booking links
  // TURNED OFF UNTIL GHA DISCOVERY LAUCHES
  /* $(
    'a[href*="reservations.travelclick.com"], a[href*="reservations.lyledc.com"], a[href*="reservations.onehundredshoreditch.com"], a[href*="be.synxis.com"]'
  )
    .not(".reservations__item-cta a")
    .on("click", function () {
      // $("#booking").foundation("open");

      // Auto populate the room or rate codes if present in the URL
      if ($(this).attr("href").indexOf("?") > -1) {
        var urlParams = new URLSearchParams($(this).attr("href").split("?")[1]);
        if (urlParams.has("RatePlanId")) {
          $('input[name="RatePlanId"]').val(urlParams.get("RatePlanId"));
        }
        if (urlParams.has("Groupid")) {
          $('input[name="Groupid"]').val(urlParams.get("Groupid"));
        }
        if (urlParams.has("RoomTypeID")) {
          $("#book-rooms form").append(
            '<input type="hidden" name="RoomTypeID" value="' +
              urlParams.get("RoomTypeID") +
              '">'
          );
        }
      }

      $('#book-rooms form').trigger('submit');

      return false;
    }); */
}

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function checkForDropdownOffers() {
  if ($(".dropdown-offers").length > 0 && $(".slider").length === 0) {
    $(".breadcrumb").css("padding-top", "167px");
  }
}

function customCookieBar() {
  if ($(".cookie-bar").length > 0) {
    if (!Cookies.get("accepted_cookies")) {
      $(".cookie-bar").fadeIn();
    }

    $(".cookie-bar__accept").on("click", function () {
      Cookies.set("accepted_cookies", "true", { expires: 30 });
      $(".cookie-bar").fadeOut();
    });
  }
}

function customLoyaltyLogin() {
  $(".reservations__list--more").accordiom({
    showFirstItem: false,
    buttonBelowContent: true,
    afterChange: function (accordionButton) {
      var $accordionButton = $(accordionButton);
      if ($accordionButton.hasClass("on")) {
        $accordionButton.find("span").text($accordionButton.data("less-text"));
      } else {
        $accordionButton.find("span").text($accordionButton.data("more-text"));
      }
    },
  });

  $(".loyalty-offers__public-offers").accordiom({
    showFirstItem: false,
  });
}

function customForms() {
  var today = new Date();
  var eventsField = $("#contact-events");

  $(".form__datepicker").datepicker({
    format: "dd/mm/yyyy",
    autoPick: false,
    autoHide: true,
    date: null,
    startDate: today,
  });

  $(".form__select select").selectric();

  eventsField.on("change", function () {
    var accFields = $(".accommodation-fields");
    var option = $(this).find(":selected").text().toLowerCase();
    if (option !== "accommodation") {
      accFields.css("display", "none");
      return;
    }
    accFields.css("display", "block");
  });
}

function initVideoPopups() {
  var $modal;
  var modal =
    '<div id="gallery__modal" class="gallery__modal gallery__modal--video full reveal loading" data-reveal><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery__modal-iframe"><iframe frameborder="0" src="{{iframeSrc}}"></iframe></div></div>';

  // Detect video links and embed them in a popup
  $('a[href*="youtu"], a[href*="vimeo.com"]')
    .not('[href*="youtube.com/channel"]')
    .addClass("is-video")
    .on("click", function () {
      $modal = $(
        modal.replace(
          "{{iframeSrc}}",
          generateVideoEmbedUrl($(this).attr("href"))
        )
      );

      $("body").append($modal);

      $("#gallery__modal").foundation();
      $("#gallery__modal").foundation("open");

      $("#gallery__modal").on("closed.zf.reveal", function () {
        $modal.remove();
      });

      return false;
    });
}

function generateVideoEmbedUrl(videoUrl) {
  if (videoUrl.indexOf("youtube.com/embed") > -1) {
    // already a YouTube embed code, return same link
    return videoUrl;
  } else if (videoUrl.indexOf("youtu") > -1) {
    // must be either a full or shortened YouTube url so parse and create embed link
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = videoUrl.match(regExp);

    if (match && match[2].length == 11) {
      return (
        "https://www.youtube.com/embed/" +
        match[2] +
        "?rel=0&autoplay=1&modestbranding=1"
      );
    } else {
      return videoUrl;
    }
  } else if (videoUrl.indexOf("player.vimeo.com") > -1) {
    // already a Vimeo embed code, but add some parameters
    if (videoUrl.indexOf("?") > -1) {
      videoUrl += "&";
    } else {
      videoUrl += "?";
    }
    return videoUrl + "autoplay=true&byline=false&portrait=false&title=false";
  } else if (videoUrl.indexOf("https://vimeo.com/") > -1) {
    // normal Vimeo link, so generate and embed code
    return (
      "https://player.vimeo.com/video/" +
      videoUrl.split("/")[3] +
      "?autoplay=true&byline=false&portrait=false&title=false"
    );
  }

  // if all else fails, return the original link
  return embedUrl;
}

initHeader = function () {
  function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function () {
      var context = scope || this;

      var now = +new Date(),
        args = arguments;
      if (last && now < last + threshhold) {
        // hold on to it
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fn.apply(context, args);
        }, threshhold);
      } else {
        last = now;
        fn.apply(context, args);
      }
    };
  }

  // Detect if the header is over and image slider, if yes then give it a transparent background
  if ($(".slider").length > 0) {
    setHeaderTransparency();
    $(window).on("scroll", setHeaderTransparency);

    function setHeaderTransparency() {
      if (window.pageYOffset < 0) {
        $(".header").addClass("header--transparent");
      } else {
        $(".header").removeClass("header--transparent");
      }
    }
  }
};

$(".header__shipmates-close").on("click", function () {
  $(".header").removeClass("shipmates-open");
  $(".header__shipmates").hide("slow");
  if (window.innerWidth < 640) {
  $('.dropdown-offers').css('top', "100px");
  } else {
    $('.dropdown-offers').css('top', "117px");
  }
});

function initImageCarousel() {
  $(".image-carousel__inner").slick({
    fade: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1500,
    fade: true,
  });
}

function initAlternatingBackgrounds() {
  var count = -1;
  $(".blog-landing-v2__content-inner").each(function () {
    if (count > 0) {
      $(this).addClass("blog-landing-v2__content-inner--dark-background");
    }
    count = count * -1;
  });

  count = -1;
  $(".offers__content-inner").each(function () {
    if (count > 0) {
      $(this).addClass("offers-v2__content-inner--dark-background");
    }
    count = count * -1;
  });

  // count = -1;
  // $('.standard-content__text-image-row').each(function () {
  // 	if ( count > 0 ) {
  // 		$(this).addClass('standard-content__text-image-row--dark-background');
  // 	}
  // 	count = count * -1;
  // });
}

function initRiggsHomeSlider() {
  $(".riggs-home__slider-inner").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    infinite: false, // needed for the right order of animation to work
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  });
}

function initCustomIntro() {
  // Need to check for no price or CTA in intro to reduce the amount of bottom padding
  if (
    $(".intro__cta-price").text() === "" &&
    $(".intro__cta .button").length == 0
  ) {
    $(".intro").addClass("intro--no-ctas");
  }
}

function initSeaContainersHome() {
  $(".sea-co-home__slider-inner").each(function () {
    $(this).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      arrows: false,
      dots: true,
      appendDots: $(this).siblings(".sea-co-home__slider-dots"),
    });
  });

  $(".sea-co-whats-on__slider-inner").slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
}

function initAccommodationDetailAccordionSlider() {
  $(".detail__accordion-image").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
  });

  const accordionButtons = $(".detail__accordion-wrap .accordionButton");
  const accordionContents = $(".detail__accordion-wrap .accordionContent");

  accordionButtons.each(function (i) {
    $(this).on("mouseover", function () {
      $(".detail__accordion-image").slick("slickGoTo", i);
    });

    $(this).on("click", function () {
      $(".detail__accordion-image").slick("slickGoTo", i);
    });
  });

  accordionContents.each(function (i) {
    $(this).on("mouseover", function () {
      $(".detail__accordion-image").slick("slickGoTo", i);
    });
  });
}

function initUmbracoDropdown() {
  $(".theme-riggs .contact select").selectric();
}

function initGHAPortal() {
	$('.header__login-button').on('click', function (e) {
		e.preventDefault();
		$('.header__login').addClass('open');
	});

	$('.header__login').on('mouseleave', function (e) {
		$('.header__login').removeClass('open');
	});

	$('.gha__form input[type="password"]').each(function () {
    $(this).parent().append('<button type="button" class="toggle-password" aria-hidden="hidden" tabindex="-1"></button>');
  });

  $('.toggle-password').on('click', function () {
    var input = $(this).siblings('input')[0];
    if (typeof input !== 'undefined') {
      if (input.type === "password") {
        input.type = "text";
				$(this).addClass('off');
      } else {
				input.type = "password";
				$(this).removeClass('off');
      }
    }
  });

	$('.gha__form select').selectric();

	$('.gha__form select').each(function () {
    customSelectricPlaceholderState(this);
  });

  $('.gha__form select').on('change', function () {
    customSelectricPlaceholderState(this);
  });

  function customSelectricPlaceholderState(selectElement) {
    if ($(selectElement).val() === '') {
      $(selectElement).parents('.selectric-wrapper').addClass('show-as-placeholder');
    } else {
      $(selectElement).parents('.selectric-wrapper').removeClass('show-as-placeholder');
    }
  }

	$('.gha__form-clear').on('click', function (e) {
		e.preventDefault();
		$(this).parents('form').find('input').val('');
	});

	if ($('#gha-portal-tabs').length > 0 && window.location.hash.indexOf('#') === 0) {
		if (window.innerWidth < 640) {
			$('.gha-portal__main-tab-list').addClass('hidden');
		}
	}

	$('.gha-portal__main-tab-list a').on('click', function () {
		if (window.innerWidth < 640) {
			$('.gha-portal__main-tab-list').addClass('hidden');
		}
	});
	
	$('.tabs-panel__title').on('click', function (e) {
		e.preventDefault();
		$('.gha-portal__main-tab-list').removeClass('hidden');
	});

	$('.gha-sign-up form, #password form').on('submit', function () {
		if ($(this).find('input[type="password"]').length > 1) {
			return validateGHAPasswords($(this));
		}
	});

	$('.gha-sign-up input[type="password"]').on('input', function () {
		return validateGHAPasswords($(this).parents('form'));
	});

	$('.gha-sign-up input').on('input', function () {
		var input = $(this)[0];

		if ($(input).attr('pattern') !== '') {
			if (input.validity.patternMismatch) {
				input.setCustomValidity($(this).data('pattern-message'));
			} else {
				input.setCustomValidity('');
			}

			input.reportValidity();
		}
	});

	$('.gha__form-nav .next').on('click', function (e) {
		e.preventDefault();

		if ($(this).parents('form').find('input[type="password"]').length > 1) {
			if (!validateGHAPasswords($(this).parents('form'))) {
				return false;
			}
		}

		var invalidFieldCount = 0;

		$(this).parents('.gha-sign-up__form-wrapper').find('input, select').each(function () {
			if (!$(this)[0].reportValidity()) {
				invalidFieldCount++;
			}
		});

		if (invalidFieldCount === 0) {
			var $currentStepWrapper = $(this).parents('.gha-sign-up__form-wrapper');
			$currentStepWrapper.hide();
			var currentStepNumber = $currentStepWrapper.data('step');
			currentStepNumber++;
			$('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').show();
			$('html, body').animate({
				scrollTop: $('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').offset().top - 120
			}, 0);
		}
	});

	$('.gha__form-nav .back').on('click', function (e) {
		e.preventDefault();
		var $currentStepWrapper = $(this).parents('.gha-sign-up__form-wrapper');
		$currentStepWrapper.hide();
		var currentStepNumber = $currentStepWrapper.data('step');
		currentStepNumber--;
		$('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').show();
		$('html, body').animate({
			scrollTop: $('.gha-sign-up__form-wrapper[data-step="' + currentStepNumber + '"]').offset().top - 120
		}, 0);
	});
}

function validateGHAPasswords($form) {
	var isValid = true;
	$form.find('.requirement').removeClass('fail');

	var $currentStep = $('.gha-sign-up__form-wrapper:visible, .gha-portal');
	if ($currentStep.find('input[type="password"]').length === 0) {
		return true;
	}

	var $password1 = $('.gha-sign-up__form-wrapper:visible #password, #NewPassword');
	var $password2 = $currentStep.find('#confirmpassword, #ConfirmPassword');
	
	if ($password1.val() !== $password2.val()) {
		$currentStep.find('.requirement.match').addClass('fail');
		isValid = false;
	}

	if ($password1.val().length < 8 || $password1.val().length > 50) {
		$currentStep.find('.requirement.length').addClass('fail');
		isValid = false;
	}

	if (!/[a-z]/.test($password1.val().toLowerCase())) {
		$currentStep.find('.requirement.letter').addClass('fail');
		isValid = false;
	}

	if (!/\d/.test($password1.val())) {
		$currentStep.find('.requirement.number').addClass('fail');
		isValid = false;
	}

	if (!/[#.?!@$%^&*-]/.test($password1.val())) {
		$currentStep.find('.requirement.symbol').addClass('fail');
		isValid = false;
	}
	
	if (/[^\x20-\x7E]+/.test($password1.val())) {
		$currentStep.find('.requirement.ascii').addClass('fail');
		isValid = false;
	}

	return isValid;
}

document.addEventListener("DOMContentLoaded", function() {
  var memberships = document.querySelector(".memberships");
  var tabs = memberships.querySelectorAll(".tabs-title");
  var panels = memberships.querySelectorAll(".tabs-panel");
  var prevBtn = memberships.querySelector(".tabs-prev");
  var nextBtn = memberships.querySelector(".tabs-next");

  var dots = document.querySelectorAll(".membership-dots .dot");
  var currentIndex = 0;

  // Set initial active tab index based on "is-active" class
  for (var i = 0; i < tabs.length; i++) {
      if (tabs[i].classList.contains("is-active")) {
          currentIndex = i;
          break;
      }
  }

  function updateDots(index) {
      for (var i = 0; i < dots.length; i++) {
          dots[i].classList.remove("active");
      }
      dots[index].classList.add("active");
  }

  function showPanel(index) {
      for (var i = 0; i < tabs.length; i++) {
          tabs[i].classList.remove("is-active");
          panels[i].classList.remove("is-active");
      }

      tabs[index].classList.add("is-active");
      panels[index].classList.add("is-active");
      currentIndex = index;
      updateDots(index); // Update dots whenever the panel is shown
  }

  function showNextPanel() {
      var nextIndex = (currentIndex + 1) % panels.length;
      showPanel(nextIndex);
  }

  function showPrevPanel() {
      var prevIndex = (currentIndex - 1 + panels.length) % panels.length;
      showPanel(prevIndex);
  }

  nextBtn.addEventListener("click", showNextPanel);
  prevBtn.addEventListener("click", showPrevPanel);

  // Add click event listener to each tab
  tabs.forEach(function(tab, index) {
      tab.addEventListener("click", function() {
          showPanel(index);
      });
  });

  // Add click event listener to each dot
  dots.forEach(function(dot, index) {
      dot.addEventListener("click", function() {
          showPanel(index);
      });
  });

  // Initialize the active panel and dot based on the initial state
  showPanel(currentIndex);
});


